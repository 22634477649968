import { useState, useEffect } from 'react'
import classNames from 'classnames';
import { MAXIMUM_GUESTS } from '../contants';
import './AddMorePeople.scss'

export default ({ id, guest, onAdd, form, totalGuests, iteration }) => {
  
 
  const [ isAdd, setAdd ] = useState(guest.add)
  const { register, watch, setValue } = form;


  // useEffect(() => {
  //   const value = watch(`people[${id}][add]`);
  //   if (value !== null && typeof value !== 'undefined') {
  //     onAdd(id, +value === 1);
  //   }

  // }, [watch(`people[${id}][add]`)])

  const handleChange = (e) => {
    const adddd = +e.target.getAttribute('data-value') === 1;
    onAdd(guest, adddd);
    setAdd(adddd)
    setValue(`people[${id}][add]`, e.target.getAttribute('data-value'));
  }

  const isChecked = guest.add ?? null;

  if (MAXIMUM_GUESTS === 0) {
    return <div style={{ height: 40 }}></div>;
  }

  if (iteration + 1 > MAXIMUM_GUESTS - 1) {
    return (
      <div className="add_more_people">
        <div>
          <input type="hidden" {...register(`people[${id}][add]`) } />
          <span>VOUS AVEZ UN +{MAXIMUM_GUESTS} ? Dites, vous avez cru que c'était vous les stars de la soirée ?</span>
        </div>
      </div>
    );
  }


  let backgroundColorYes = 'transparent';
  let backgroundColorNo = 'transparent';


  if (isChecked !== null) {
    backgroundColorYes = isChecked === true ? 'limegreen' : '#ccc';
    backgroundColorNo = !isChecked === true ? 'limegreen' : '#ccc';
  }

  return (
    <div className="add_more_people">
      <div>
        <input type="hidden" {...register(`people[${id}][add]`) } />
        <span>Vous venez accompagné(e) ?</span>
      </div>
      <div className="add_more_people__buttons">
        <button className={classNames('add_more_people__button', { 'add_more_people__button--checked': isChecked !== null && isChecked})} data-value="1" type="button" onClick={handleChange} id={`people_${id}_add_yes`}>Oui</button>
        <button className={classNames('add_more_people__button', { 'add_more_people__button--checked': isChecked !== null && !isChecked })} data-value="0" type="button" onClick={handleChange} id={`people_${id}_add_no`}>Non</button>
      </div>
    </div>
  );
}