import "./App.css";
import "./checkbox.scss";
import "./fonts.scss";
import "normalize.css";
import classNames from "classnames";
import { useState } from "react";

import Header from "./sections/Header";
import Form from "./sections/Form";
import Intro from "./sections/Intro";
import Footer from "./sections/Footer";
import Finish from "./sections/Finish";
import AnimIntro from "./sections/AnimIntro";
import ResponseSelector from "./sections/ResponseSelector";

function App() {
  const [step, setStep] = useState(0);
  const [isComing, setIsComing] = useState(null);

  return (
    <div className="App">
      {step === 0 && (
        <>
          {/* <AnimIntro /> */}
          <Header />
          <Intro />
          <section>
            <h4 className="booking__subtitle">
              Pose de la première pierre du futur centre de tri
              <br />
              Vendredi 28 juin - 12h
            </h4>
            <p className="" style={{ marginTop: 32 }}>
              Savoie Déchets initie le lancement de la construction de son
              nouveau centre de tri des collectes sélectives, confiée au
              groupement AKTID (mandataire), Legendre Construction, SW
              Architecture. Cette nouvelle installation, située en face de
              l’usine de valorisation énergétique et de traitement des ordures
              ménagères de Chambéry, aura la capacité de trier les déchets
              recyclables des habitants de la Savoie et de territoires voisins.
              <br />
              Pour réaliser ce projet, Savoie Déchets s’est associé avec quatre
              territoires voisins : la communauté de communes de Bugey Sud,
              celle des Sources du Lac d’Annecy, SYCLUM et le Sibrecsa (Bréda et
              Combe de Savoie). Pour l’ensemble des collectivités adhérentes et
              partenaires, le positionnement du nouveau centre de tri de
              Chambéry constitue un moyen de limiter le transport de déchets.
            </p>
            <p style={{ fontWeight: "bold", marginTop: 16 }}>
              Vendredi 28 juin - 12h - 190 rue Pré Demaison, Chambéry{" "}
            </p>
            <ResponseSelector onChange={(isComing) => setIsComing(isComing)} />
            {isComing !== null && (
              <Form
                isComing={isComing}
                onSuccess={() => {
                  setStep(1);
                }}
              />
            )}
          </section>
          <Footer />
        </>
      )}
      {step === 1 && (
        <Finish
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setStep(0);
          }}
        />
      )}
    </div>
  );
}

export default App;
