import { useForm } from "react-hook-form";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import BookingItem from "../components/BookingItem";
import AddMorePeople from "../components/AddMorePeople";
import { MAXIMUM_GUESTS } from "../contants";

import "./Form.scss";
import FirebaseForm from "../components/FirebaseForm";

export default ({ isComing, onSuccess }) => {
  const form = useForm();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = form;

  const [guestsNumber, setGuestsNumber] = useState(
    Array.from({ length: 1 }, (i, idx) => ({
      id: uuidv4(),
      add: null,
      id_next: null,
    }))
  );

  const [inSubmittedRequest, setSubmitStatus] = useState(false);

  const handleAddPeople = (guest, add) => {
    let newGuests = [...guestsNumber];

    const c = newGuests.findIndex(({ id }) => id === guest.id);

    if (!add) {
      newGuests[c].add = false;

      const t = newGuests.findIndex(({ id }) => id === guest.id_next);

      if (t === -1) {
        return;
      }

      const next_id =
        typeof newGuests[t + 1] !== "undefined" ? newGuests[t + 1].id : null;

      newGuests.splice(t, 1);
    }

    if (add && newGuests.length + 1 <= MAXIMUM_GUESTS) {
      const t = newGuests.findIndex(({ id }) => id === guest.id);
      const newIndex = uuidv4();
      newGuests[c].add = true;
      newGuests[c].id_next = newIndex;
      newGuests.splice(t + 1, 0, { id: newIndex, add: null, id_next: null });
    }

    newGuests = newGuests.map(({ id, add, id_next }, idx) => {
      const next_id =
        typeof newGuests[idx + 1] !== "undefined"
          ? newGuests[idx + 1].id
          : null;
      return {
        id,
        add: next_id == id_next ? add : next_id === null ? false : true,
        id_next: next_id == id_next ? id_next : next_id,
      };
    });

    setGuestsNumber(newGuests);
  };

  const handleChangeEmail = (currentId) => {};

  return (
    <>
      <FirebaseForm form={form} guests={guestsNumber} onSuccess={onSuccess}>
        <div>
          {guestsNumber.map((guest, idx) => {
            const guestError = errors?.people?.[`${guest.id}`];
            return (
              <div key={`booking_item_${guest.id}_${isComing ? 1 : 0}`}>
                <BookingItem
                  isComing={isComing}
                  id={guest.id}
                  errors={guestError}
                  onChangeEmail={handleChangeEmail}
                  linkedGuest={guestsNumber[0]}
                  iteration={idx}
                  guest={guest}
                  form={form}
                />
                {isComing && <AddMorePeople id={guest.id} iteration={idx} totalGuests={guestsNumber.length} guest={guest} onAdd={handleAddPeople} form={form} />}
              </div>
            );
          })}
        </div>

        <p style={{ padding: "1rem 0", fontSize: 12, marginTop: 16 }}>
          Le service communication de Savoie Déchets traite les
          données recueillies.
          <br />
          Les données dans le formulaire doivent obligatoirement être fournies.
          <br />
          Dans le cas contraire, votre inscription ne pourra être traité. <br />
          Pour en savoir plus sur la gestion de vos données personnelles et pour
          exercer vos droits, reportez-vous aux {' '}
          <a
            href="https://www.savoie-dechets.com/Mentions-legales"
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            Mentions légales
          </a>
          .
        </p>

        <div className="" style={{ textAlign: 'center' }}>
          <button className="booking__submit_button" type="submit">
            Je m'inscris
          </button>
        </div>
      </FirebaseForm>
    </>
  );
};
