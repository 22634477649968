import "./BookingItem.scss";
import ErrorInput from "./ErrorInput";

export default ({
  isComing,
  id,
  form,
  iteration,
  errors,
  linkedGuest,
  onChangeEmail,
}) => {
  const { register } = form;

  console.log("BookingItem >>>", isComing, isComing ? 1 : 0);

  const handleChangeEmail = (e) => {
    // onChangeEmail(id, e.target.value)
    // console.log('-->', e.target.value)
  };

  const days = [
    // { label: "Je viens", name: "im_coming" },
    // { label: "Je viens accompagné(e)", name: "with_someone" },
  ];

  return (
    <div className="booking__item__input__container">
      <input
        type="hidden"
        {...register(`people[${id}][ts]`)}
        value={Date.now()}
      />
      <input
        type="hidden"
        {...register(`people[${id}][coming]`)}
        value={isComing ? 1 : 0}
      />
      <input
        type="hidden"
        {...register(`people[${id}][index]`)}
        value={iteration}
      />
      <input type="hidden" {...register(`people[${id}][id]`)} value={id} />
      <input
        type="hidden"
        {...register(`people[${id}][link_id]`)}
        value={linkedGuest.id}
      />

      <label
        htmlFor={`people_${id}_organisation`}
        className="booking__item__input_mail"
      >
        <span>Organisation</span>
        <input
          {...register(`people[${id}][organisation]`, { required: false })}
          id={`people_${id}_organisation`}
        />
        {errors?.organisation && <ErrorInput>Votre organisation est requis.</ErrorInput>}
      </label>

      <label
        htmlFor={`people_${id}_lastname`}
        className="booking__item__input_mail"
      >
        <span>VOTRE nom *</span>
        <input
          {...register(`people[${id}][lastname]`, { required: true })}
          id={`people_${id}_lastname`}
        />
        {errors?.lastname && <ErrorInput>Votre nom est requis.</ErrorInput>}
      </label>

      <label
        htmlFor={`people_${id}_firstname`}
        className="booking__item__input_mail"
      >
        <span>VOTRE prénom *</span>
        <input
          {...register(`people[${id}][firstname]`, { required: true })}
          id={`people_${id}_firstname`}
        />
        {errors?.firstname && <ErrorInput>Votre prénom est requis.</ErrorInput>}
      </label>

      <label
        htmlFor={`people_${id}_email`}
        className="booking__item__input_mail"
      >
        <span>VOTRE adresse e-mail *</span>
        <input
          {...register(`people[${id}][email]`, { required: true })}
          id={`people_${id}_email`}
          placeholder="exemple@mail.com"
        />
        {errors?.email && <ErrorInput>Votre email est requis.</ErrorInput>}
      </label>

      <div className="booking__item__radio__part__container">
        {days.map(({ name, label }, index) => {
          return (
            <div className="booking__item__radio__part" key={index}>
              <p className="booking__item__radio__title">{label} *</p>
              <div className="booking__item__radio__container">
                <label
                  htmlFor={`people_${id}_radio_${name}_1`}
                  className="booking__item__input booking__item__input--boolean c_checkbox"
                >
                  <input
                    {...register(`people[${id}][${name}]`, { required: true })}
                    value="1"
                    type="radio"
                    id={`people_${id}_radio_${name}_1`}
                  />
                  <span className="c_checkbox__mark"></span>
                  <span className="text">Oui</span>
                </label>

                <label
                  htmlFor={`people_${id}_radio_${name}_2`}
                  className="booking__item__input booking__item__input--boolean c_checkbox"
                >
                  <input
                    {...register(`people[${id}][${name}]`, { required: true })}
                    value="0"
                    type="radio"
                    id={`people_${id}_radio_${name}_2`}
                  />
                  <span className="c_radio__mark"></span>
                  <span className="text">Non</span>
                </label>
              </div>
              {errors && typeof errors[`${name}`] !== "undefined" && (
                <ErrorInput>Vous devez définir votre participation</ErrorInput>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
