import "./Footer.scss";
import footerLogo from "../img/savoie-dechet-logo.png";
import footerPartners from "../img/footer-partners.svg";
export default () => {
  return (
    <section className="footer">
      <img src={footerLogo} alt="" style={{ maxWidth: 150 }} />
      {/* <img src={footerPartners} alt="" /> */}

      {/* <a href="https://gresy-sur-aix.fr/contact/" target="_blank">Charte de protection des données personnelles</a> */}
    </section>
  );
};
