import "./Header.scss";
import logoPaulette from "../components/logo-paulette.svg";
import logoStudioFilmiz from "../components/logo-studio-filmiz.svg";
import { ReactComponent as ReactLogos } from "../img/logos.svg";
import "lite-youtube-embed";
import "lite-youtube-embed/src/lite-yt-embed.css";

import scroll from "../img/scroll.svg";
import headerLogo from "../img/savoie-dechet-photo.jpg";
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin)

export default () => {

  const handleScrollClick = () => {
    gsap.to(window, {duration: 1, scrollTo: {y: '.intro', offsetY: 47 }});
  }

  return (
    <div
      className="header"
      // style={{
      //   backgroundImage: `url(${bgImage})`,
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      // }}
    >
      {/* <div className="header__img"></div> */}
      {<img src={headerLogo} alt="" />}
      <div className="container">
      <svg
        width="193"
        height="193"
        viewBox="0 0 193 193"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="header__scroll"
        onClick={handleScrollClick}
      >
        <circle
          cx="96.4748"
          cy="96.0311"
          r="85.5"
          transform="rotate(7.57988 96.4748 96.0311)"
          fill="black"
        />
        <path
          d="M100 43C100 41.3431 98.6569 40 97 40C95.3431 40 94 41.3431 94 43L100 43ZM94.8787 149.121C96.0503 150.293 97.9498 150.293 99.1213 149.121L118.213 130.029C119.385 128.858 119.385 126.958 118.213 125.787C117.042 124.615 115.142 124.615 113.971 125.787L97 142.757L80.0294 125.787C78.8579 124.615 76.9584 124.615 75.7868 125.787C74.6152 126.958 74.6152 128.858 75.7868 130.029L94.8787 149.121ZM94 43L94 147L100 147L100 43L94 43Z"
          fill="white"
        />
      </svg>
      </div>
    </div>

    // <div className="header">
    //   <div className="header__video">
    //     <lite-youtube  videoid="vQgOVw4wyZQ" params="loop=1&controls=0&showinfo=0&rel=0" style={{backgroundImage: `url(${bgImage})`}} >
    //     {/* <iframe style={{ width: '100%', height: '100%'}} src="https://www.youtube.com/embed/vQgOVw4wyZQ?controls=0&loop=1&modestbranding=1&playsinline=1&showinfo=0" title="GRESY SUR AIX   Tiers lieu" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>` */}
    //     </lite-youtube>

    //   </div>

    // </div>
  );
};
