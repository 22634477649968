import './Finish.scss';

export default ({ onClick }) => {
  return (
    <div className="finish">
      <a href="#" onClick={onClick}>Merci, votre réponse a bien été prise en compte.</a>
      <div style={{ marginTop: '2rem'}}>
        <p style={{ marginBottom: '2rem'}}>Retrouvez l'intinéraire pour vous rendre à l'événement en cliquant sur le bouton ci-dessous.</p>
        <a class="button" href="https://maps.app.goo.gl/uBhQouwGNqKt2X479" target="_blank">190 rue Pré Demaison, Chambéry</a>
      </div>
    </div>
  );
}